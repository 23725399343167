export default {
    apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://gp23pj3d60.execute-api.eu-west-1.amazonaws.com/dev',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'gd-api',
        GEN_SERVICE_BASE: '/gen',
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_L6zbtTI1R',
        APP_CLIENT_ID: '5voukkgbpk3lq7bh80ad65evt5',
        IDENTITY_POOL_ID: 'eu-west-1:a68c2ac5-a103-4337-9adc-5860e66f2ee2'
    },
    // s3: {
    //     REGION: 'eu-west-1',
    //     BUCKET: 'ela-user-bucket'
    // }
};