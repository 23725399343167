import React, { Component } from 'react'
import { NotificationContainer } from 'react-notifications'
import { Auth } from 'aws-amplify'
import GuestRouter from './config/routing/GuestRouter'
import AdminRouter from './config/routing/AdminRouter'

// Turn off logs in production
if (process.env.NODE_ENV !== 'development') {
    console.log = function() {}
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: null,
            loading: true
        }
        //this.apiclient = new apiclient()
        console.log('auth state: ', this.props.authState)
    }

    async componentWillMount() {
        const info = await Auth.currentUserInfo()
        console.log('user info: ', info)

        const credentials = await Auth.currentCredentials()
        console.log('user credentials: ', credentials)

        if (info) {
            this.setState({
                email: info.attributes['email'],
                loading: false,
            })
        }
    }

    render() {
        return (
            <div id='app'>
                <NotificationContainer />
                {this.props.authState === 'signedIn' ? (
                    <div>
                        <AdminRouter />
                    </div>
                ) : (
                    <div>
                        <GuestRouter />
                    </div>
                )}
            </div>
        );
    }
}

export default App