import React from 'react'
import { 
    Switch, 
    Route, 
    Redirect 
} from 'react-router-dom'
import Login from '../../scenes/general/Login'

const Main = () => (
	<main>
        <Switch>
        	<Route exact path='/' component={Login} />
            <Route path='/login' component={Login} />
        </Switch>
	</main>
);

export default Main