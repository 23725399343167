import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Button } from 'reactstrap'

class RoundedButton extends Component {
	constructor(props) {
	    super(props)
	}

	render() {
		return (
			<Button 
				className={'rounded-button ' + this.props.color + (this.props.margin ? ' button-margin' : '') + (this.props.className ? ' ' + this.props.className : '')} 
				onClick={(this.props.onClick) ? () => this.props.onClick() : console.log('no onClick')}
				disabled={this.props.disabled}
				type={this.props.type}
				style={this.props.style}
			>
				{this.props.text.toUpperCase()}
			</Button>
	    )
	}
}

export default RoundedButton