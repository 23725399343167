import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
//import 'raf/polyfill'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {BrowserRouter} from 'react-router-dom'

import Amplify from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'
import Config from './config/api/Config'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'react-notifications/lib/notifications.css'
import './css/ReactTable.css'
import './css/App.css'
import './css/Responsive.css'


Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        identityPoolId: Config.cognito.IDENTITY_POOL_ID, 
        region: Config.cognito.REGION, 
        userPoolId: Config.cognito.USER_POOL_ID,
        userPoolWebClientId: Config.cognito.APP_CLIENT_ID, 
    },
    API: {
        endpoints: [
            {
                name: Config.apiGateway.PATH,
                endpoint: Config.apiGateway.URL,
                region: Config.apiGateway.REGION
            },
        ]
    }
});

class AppWithAuth extends Component {
    render() {
        return (
            // Put the App component inside the authenticator component and only render once correct authState is reached
            <Authenticator hideDefault={true}>
                <App />
            </Authenticator>
        );
    }
}

ReactDOM.render(<BrowserRouter><AppWithAuth /></BrowserRouter>, document.getElementById('root'));
