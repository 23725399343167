import { Component } from 'react'
import { Auth, API, Storage } from 'aws-amplify'
import config from './Config'
import uuid from 'uuid'

const apiPath = config.apiGateway.PATH

class GenClient extends Component {
	sendNotifications(body, url) {
		let data = {
			body: body,
			url: url
		}
		console.log(data)

		return API.post(apiPath, '/notifications/send', {
			body: data
		})
	}

	updateNotifications(pushToken, notifications) {
		let data = {
			pushToken: 'ExponentPushToken[WUiW5hC_kKTpefOsPBCfPy]',
			notifications: false
		}
		console.log(data)

		return API.post(apiPath, '/notifications/update', {
			body: data
		})
	}
} 

export default GenClient