import React, { Component } from 'react'
import { Input, Button, Form } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			email: '',
			password: '',
			emailRegex: null,
			passwordRegex: null,
		}
	}

	checkEmail(input) {
		var regexEmail = /^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (regexEmail.test(input)) {
			this.setState({emailRegex: 'success'});
		}
		else {
			this.setState({emailRegex: 'warning'});
		}
	}

	checkPassword(input) {
		var regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

		if (regexPassword.test(input)) {
			this.setState({passwordRegex: 'success'});
		}
		else {
			this.setState({passwordRegex: 'warning'});
		}
	}

	validateForm() {
		if (this.state.emailRegex === 'success' && this.state.passwordRegex === 'success') {
			return true;
		}
		else {
			return false;
		}
	}

	handleEmailChange(email) {
		this.setState({
			email: email
		})
	}

	handlePasswordChange(password) {
		this.setState({
			password: password
		})
	}

	handleSubmit = async event => {
		event.preventDefault()
		this.login()
	}

	async login() {
		await this.setState({
			loading: true
		})

		try {
			const user = await Auth.signIn(this.state.email, this.state.password)
			console.log(user)
		} catch(err) {
			if (err.code === 'UserNotConfirmedException') {
				NotificationManager.error('You must confirm your account in order to log in. Please check your email for a confirmation link')
			}
			else if (err.code === 'UserNotFoundException') {
				NotificationManager.error('An account with this email address has not yet been registered.')
			}
			else if (err.code === 'NotAuthorizedException') {
				NotificationManager.error('The email address or password you have entered is incorrect')
			}
			console.log(err)
			this.setState({
				loading: false
			})
		}
	}

	render() {
		return (
			<Form className='login-form' onSubmit={this.handleSubmit}>
				<h1>Login</h1>

				<Input 
					className='login-input login-form-group'
					placeholder='Email'
					onChange={e => this.handleEmailChange(e.target.value)}
					value={this.state.email}
				/>

				<Input 
					className='login-input login-form-group'
					placeholder='Password'
					type='password'
					onChange={e => this.handlePasswordChange(e.target.value)}
					value={this.state.password}
				/>

				<Button className={'login-button'} type={'submit'}>
					Login
				</Button>
			</Form>
	    )
	}
}

export default withRouter(LoginForm)