import React from 'react'
import { 
    Switch, 
    Route, 
    Redirect 
} from 'react-router-dom'
import Dashboard from '../../scenes/admin/Dashboard'

const Main = () => (
	<main>
        <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route path='/dashboard' component={Dashboard} />
        </Switch>
	</main>
);

export default Main