import React, { Component } from 'react'
import { 
	Row, 
	Col,
	Form,
	Input,
	Button,
	FormGroup,
	Label,
	FormFeedback
} from 'reactstrap'
import RoundedButton from '../general/RoundedButton'
import moment from 'moment'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'
import apiclient from '../../config/api/GenClient'

class SendNotificationsForm extends Component {
	constructor(props) {
	    super(props)
	    this.state = {
	    	body: '',
	    	url: ''
	    }

	    this.apiclient = new apiclient()
	}

	handleSubmit = async event => {
		event.preventDefault()
		this.submitData()
	}

	async submitData() {
		console.log(this.state)

		try {
			await this.apiclient.sendNotifications(this.state.body, this.state.url)

			NotificationManager.success('Notifications queued for sending')

			this.setState({
		    	body: '',
		    	url: ''
			})
		} catch(e) {
			console.log(e)
			NotificationManager.error('Error sending notifications')
		}
	}

	validateForm() {
		if (this.state.body.length > 0 && this.state.url.length > 0) {
			return true
		}
		else {
			return false
		}
	}

	updateBody(body) {
		this.setState({
			body: body
		})
	}

	updateUrl(url) {
		this.setState({
			url: url
		})
	}

	render() {
		return (
			<Form className='data-capture-form' onSubmit={this.handleSubmit}>

				<h4>Send Notifications</h4>

				<Input 
					className='form-input'
					placeholder='Body'
					onChange={e => this.updateBody(e.target.value)}
					value={this.state.body}
				/>

				<Input 
					className='form-input'
					placeholder='URL (link to article or page)'
					onChange={e => this.updateUrl(e.target.value)}
					value={this.state.url}
				/>

				<RoundedButton 
					className={'submit-details-button'} 
					type={'submit'} 
					text={'submit'} 
					disabled={!this.validateForm()}
				/>
			</Form>
	    )
	}
}

export default SendNotificationsForm