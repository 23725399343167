import React, { Component } from 'react'
import apiclient from '../../config/api/GenClient'
import { PulseLoader } from 'react-spinners'
import { Auth } from 'aws-amplify'
import RoundedButton from '../../components/general/RoundedButton'
import SendNotificationsForm from '../../components/admin/SendNotificationsForm'

class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
		this.apiclient = new apiclient()
	}

	async componentWillMount() {
		try {
			this.setState({
				loading: false
			})
		} catch(e) {
			console.log(e)
		}
	}

	async logout() {
		try {
			await Auth.signOut()
			this.props.history.push('/')
		} catch(e) {
			console.log(e)
		}
    }

	render() {
		return (
			<div>
	            <div className='events-container'>
	            	{!this.state.loading &&
		                <div className='events-card'>
		                	<SendNotificationsForm />
		                </div>
	            	}
	            	{this.state.loading &&
	            		<div className='events-card loader'>
		            		<div className='loader-container'>
		            			<PulseLoader
		            				color={'#FF3D00'} 
						          	loading={this.state.loading} 
		            			/>
		            		</div>
	            		</div>
	            	}
	            	<RoundedButton 
						text='log out'
						onClick={() => this.logout()}		
					/>
	            </div>
            </div>
	    )
	}
}

export default Dashboard