import React, { Component } from 'react'
import {
	Container,
	Row,
	Col,
} from 'reactstrap'
import LoginForm from '../../components/general/LoginForm'

class Login extends Component {
	render() {
		return (
            <div className='login-container'>
                <LoginForm />
            </div>
	    )
	}
}

export default Login